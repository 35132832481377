@import './../../import.css';

.form-fields .form-field__item,
.content .form-fields .form-field__item {
  &-hint {
    font: 0.85em var(--font);
    color: #000;
    position: absolute;
    left: 0;
    bottom: -3em;
    padding: 0.5em 1em 0.6em 1em;
    line-height: 1.3em;
    background: #eee;
    z-index: 1;
    display: none;
    &::after {
      width: 8px;
      height: 8px;
      transform-origin: 100% 100%;
      transform: rotate(135deg);
      background: #eee;
      position: absolute;
      left: 2em;
      margin: 0 0 0 -18px;
      top: -8px;
      content: '';
      display: block;
      z-index: 2;
    }
    &-link {
      color: #369;
    }
  }
}
