@import './../import.css';

.form {
  position: relative;
  margin: 25px 0 0 0;
  &-inner {
    position: relative;
    @media (--mobile), (--tablet) {
      padding: 0 20px;
    }
  }
}
