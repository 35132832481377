@import './../../import.css';

.form-checkbox_state_checked {
  position: relative;
  & .form-checkbox__label::before {
    font: normal normal normal 14px/1 FontAwesome;
    content: '\f00c';
    display: block;
    position: absolute;
    left: 0.15em;
    top: 0.95em;
    color: #000;
    z-index: 2
  }
}
