@import './../import.css';

.form_type_filter {
  & .form-fields {
    width: 100%;
  }
    & .form-field__item {
      width: 24%;
      float: left;
      min-height: 67px;
      &-c1 {
        width: 100%;
        display: block;
        padding: 0;
      }
        &-name {
          width: 100%;
          min-width: 100% !important;
          text-align: left;
          padding: 0;
        }
      &-c2 {
        display: block;
      }
    }
}
