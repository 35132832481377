@import './../../import.css';

.form-calendar {
  width: 100%;
  background: #fff;
  border: solid 1px #eee;
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0 0.1em 0.1em #cfcfcf;
  font: 0.85em var(--font);
  color: #333333;
  position: relative;
  padding: 0.5em;
  outline-width: 0;
  transition: all 200ms linear;
  &:focus {
    background: #fff;
  }
}

.xdsoft_datetimepicker {
  z-index: 100000000 !important;
}
