@import './../../import.css';

.form-message {
  font: 0.95em var(--font);
  padding: 1em 0.5em 1em 0.5em;
  text-align: center;
  vertical-align: middle;
  display: none;
}

div.page div.form-message {
  display: block;
}
