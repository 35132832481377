@import './../../import.css';

.form-input {
  width: 100%;
  background: #fff;
  display: block;
  font: 0.85em var(--font);
  color: #333333;
  position: relative;
  outline-width: 0;
  transition: all 200ms linear;
  border: 1px solid #CFCFCF;
  padding: 0.7em 0.5em 0.6em 0.8em;
  border-radius: 5px;
  box-shadow: inset 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  &:focus {
    background: #fff;
    border: solid 1px #222;
  }
  &:-webkit-autofill {
    color: #fff;
  }
}
