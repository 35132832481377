@import './../import.css';

.input {
  background: #fff;
  border-left: solid 1px #c6c6c6;
  border-top: solid 1px #a3a3a3;
  border-right: solid 1px #c6c6c6;
  border-bottom: solid 1px #e5e5e5;
  display: block;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px #cfcfcf;
  font: 14px var(--font);
  color: #333333;
  width: 99%;
  position: relative;
  padding: 0.3em 1% 0.2em 0.3em;
  outline-width: 0;
  border-radius: 4px;
  transition: all 200ms linear;
  &:focus {
    border-left: solid 1px #FF6702;
    border-top: solid 1px orange;
    border-right: solid 1px #FF6702;
    border-bottom: solid 1px orange;
    box-shadow: inset 0 1px 1px #ffa119;
    transition: all 200ms linear;
  }
}
