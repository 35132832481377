@import './import.css';

.no-filereader {
  & .dropzone {
    display: none !important;
  }
}

div.dropzone {
  border: 2px dashed #ccc;
  background: white;
  min-height: 1px;
  border-radius: 5px;
  text-align: center;
  display: table;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  &:hover {
    border: 2px dashed #bbb;
  }
  & .dz-message {
    cursor: pointer;
    font: 0.95em var(--font);
    line-height: 1.6em;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding: 50px 20px;
  }
  & .dz-image {
    border: solid 1px #ccc;
  }
  & .dz-preview {
    margin: 20px 20px 10px 20px;
    &:hover .dz-image {
      border: solid 1px #000;
      cursor: pointer;
      & img {
        filter: none !important;
        transform: none !important;
      }
    }
    & .dz-details {
      width: 120px;
      position: relative;
      margin: 10px 0 0 0;
      color: #000;
      padding: 0;
      opacity: 100;
      font: 0.8em var(--font);
      & .dz-size {
        margin: 0;
        font-size: 1em;
      }
      & .dz-filename:hover span {
        border: none;
        background: none;
      }
    }
    & .dz-progress {
      border: solid 1px #ccc;
      top: 40%;
    }
    & .dz-success-mark,
    & .dz-error-mark {
      top: 38%;
    }
  }
}
