@import './../../import.css';

.form-header {
  padding: 0 0 0 20px;
  p {
    font: 0.85em var(--font);
    color: #0000;
    padding: 0 0 20px 0;
    margin: 0;
    line-height: 1.6em;
    &:last-child {
      padding: 0 0 10px 0;
    }
  }
  &__text {
    font: 0.85em var(--font);
    color: #0000;
    padding: 0 0 20px 0;
    margin: 0;
    line-height: 1.6em;
  }
}
