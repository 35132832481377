@import './../../import.css';

.form-button {
  display: inline-block;
  position: relative;
  padding: 0.4em 1em 0.4em 1em;
  text-align: center;
  font: 1.4em var(--font);
  border-radius: 0.15em;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  color: #444;
  border: solid 1px #ccc;
  background: #f5f5f5;
  background: linear-gradient(to bottom, #f5f5f5 0%, #dfdfdf 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='#f5f5f5', endcolorstr='#dfdfdf', gradienttype=0);
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &::before {
    width: 99%;
    height: 93%;
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    bottom: 0;
    left: 0.5%;
    right: 0;
    box-shadow: 0 3px 5px #959697;
    border-radius: 5px;
  }
}
