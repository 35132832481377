@import './../import.css';

.no-flexbox {
  & .browser-warning {
    display: block;
  }
}

.browser-warning {
  background: maroon;
  font: 14px var(--font);
  color: #fff;
  padding: 20px 30px 10px 30px;
  display: none;
  & p {
    padding: 0 0 10px 0;
    margin: 0;
  }
  & a {
    color: #fff;
  }
}
