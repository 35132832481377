@import './../import.css';

.form-group {
  width: 46%;
  display: inline-block;
  vertical-align: top;
  padding: 15px 10px 25px 10px;
  border: solid 1px #fff;
  margin: 1em 0 0 0;
  transition: all 200ms linear;
  &:hover {
    box-shadow: 0 0 10px #ccc;
    transition: all 200ms linear;
  }
  &__title {
    white-space: nowrap;
    font: 1.2em Arial;
    color: #000;
    position: relative;
    padding: 1em 0 0.5em 0;
  }
  &__list {
    width: 100%;
    display: table;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
