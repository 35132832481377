@import './../../import.css';

.form-footer {
  position: relative;
  & h4 {
    font: bold 1.1em var(--font);
    color: #656565;
    padding: 0;
    margin: 0;
  }
  & p {
    font: 0.9em var(--font);
    color: #656565;
    padding: 0;
    margin: 0;
  }
  &__text {
    font: 0.85em var(--font);
    color: #0000;
    padding: 0 0 20px 0;
    margin: 0;
  }
}
