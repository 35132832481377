@import './../../import.css';

.form-requirement-field {
  padding: 0.5em 1em 0.7em 1em;
  line-height: 1.3em;
  background: #eee;
  &__title {
    font: bold 0.8em var(--font);
    color: #333;
    display: inline-block;
    padding: 0 0.3em 0 0;
  }
  &__link {
    text-decoration: none;
    display: inline-block;
    margin: 0 0.2em 0 0;
    color: #369;
    &:hover .form-requirement-field__link-text {
      color: #d5542f;
      border-bottom: dashed 1px #d5542f;
    }
    &:last-child .form-requirement-field__link-separator {
        display: none;
    }
    &-text {
      font: 0.8em var(--font);
      color: #369;
      border-bottom: dashed 1px #369;
      text-transform: lowercase;
      display: inline-block;
    }
    &-separator {
      font: 0.8em var(--font);
      color: #369;
      display: inline-block;
      position: relative;
      margin: 0 0 0 -0.2em;
    }
  }
}
