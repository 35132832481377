@import './../import.css';

.form-informer {
  font: 0.9em var(--font);
  padding: 0.7em 0.5em 0.7em 0.7em;
  color: #fff;
  text-align: center;
  background: #036;
  margin: 0 0 0.5em 0;
}
