@import './../../import.css';

.form-checkbox {
  position: relative;
  margin: 0 0 0.5em 0;
  display: block;
  &__label {
    position: relative;
    display: block;
    padding: 0.7em 0 0.6em 0;
    &::after {
      width: 1.1em;
      height: 1.1em;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.7em;
      border: solid 1px #ccc;
      background: #fff;
      border-radius: 5px;
      box-shadow: inset 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      z-index: 1;
    }
  }
    &__checkbox {
      position: absolute;
      top: 0.1em;
      left: 0;
      z-index: 3;
      visibility: hidden;
    }
    &__name {
      padding: 0 0 0.1em 25px;
      font: 0.9em var(--font);
      color: #444;
      text-align: left;
      display: inline-block;
      cursor: pointer;
    }
    &__required {
      font: 1em var(--font);
      color: orange;
    }
}
