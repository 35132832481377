@import './../../import.css';

.form-response {
  text-align: center;
  &__item {
    font: 0.9em var(--font);
    padding: 0.7em 0.5em 0.7em 0.7em;
    margin: 0 0 0.5em 0;
  }
  &_type_success {
    background: #00b159;
    color: #fff;
  }
  &_type_error {
    background: red;
    color: #fff;
  }
  &_type_debug {
    background: #d2d2d2;
  }
  &_type_info {
    background: #00aedb;
    color: #fff;
  }
  &_type_warning {
    background: #ffc425;
  }
}
