@import './../../import.css';

.form-radioselect-item_state_checked {
  position: relative;
  & .form-radioselect-item__label::before {
    width: 0.6em;
    height: 0.6em;
    content: '';
    display: block;
    position: absolute;
    left: 0.25em;
    top: 0.25em;
    background: #000;
    border-radius: 100px;
    z-index: 2;
  }
}
