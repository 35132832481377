@import './../import.css';

.popup-loading {
  width: 100%;
  height: 100%;
  background: #fff url('./../img/loader.gif') no-repeat 50% 50%;
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
}
