@import './../../import.css';

ul.form-fields,
.content ul.form-fields {
  width: 100%;
  position: relative;
  display: table;
  border-collapse: separate;
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
  @media (--mobile), (--tablet) {
    display: block;
  }
  & .form-field__item {
    display: table-row;
    @media (--mobile), (--tablet) {
      display: block;
    }
    &-c2-inner {
      margin-right: 1em;
    }
  }
}
