@import './../import.css';

.captcha {
  //zoom: 1;
  padding: 0 0 0.5em 0;
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  &-image {
    float: left;
    text-align: center;
    &__image {
      display: block;
    }
  }
  &__link {
    font: 0.8em var(--font);
    text-decoration: none;
    color: #369;
    border-bottom: dashed 1px #369;
    &:hover {
      color: #d5542f;
      border-bottom: dashed 1px #d5542f;
    }
  }
  &-input {
    width: 90px;
    float: left;
    padding: 10px 0 0 20px;
    & .form-field-message_type-error {
      white-space: nowrap;
    }
  }
}
