@import './../import.css';

.popup_state_message {
  & .popup-content {
    &__link-text {
      color: #fff;
      border-bottom: dashed 1px #fff;
    }
    &__inner {
      width: 100%;
      display: table;
      padding: 0;
      & .form-response {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
