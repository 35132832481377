@import './../import.css';

.form-link {
  padding: 1em 0 0 0;
  font: 0.8em var(--font);
  &__link {
    color: #0f6fb3;
    &:hover {
      color: #dd1919;
    }
  }
}
