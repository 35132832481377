@import './../../import.css';

.form-field-message {
  display: none;
  &_type-error {
    color: #fff;
    display: inline-block !important;
    background: red;
    z-index: 2;
    font: 0.85em var(--font);
    padding: 0.5em 0.8em 0.6em 0.8em;
    line-height: 1.3em;
    margin: 0.5em 0 0 0;
  }
}

/*
.form-field-message_type-error:after
{
  font: 1.2em FontAwesome;
  content: "\f057";
  display: inline-block;
  color: #ff2e2e;
}

.form-field-message_type-valid:after
{
  font: 1.2em FontAwesome;
  content: "\f058";
  display: inline-block;
  color: #00aa4f;
}
*/
