@import './../import.css';

.form-image-list {
  &__item {
    width: 33%;
    float: left;
    padding: 1em 0 1em 0;
    text-align: center;
    &:hover {
      background: #eee;
    }
    &-image {
      &-image {
        vertical-align: bottom;
      }
    }
    &-show {
      display: block;
      font: 0.8em var(--font);
      color: #000;
      padding: 0.5em 0 0 0;
      cursor: pointer;
      &:hover {
        color: #dd1919;
      }
    }
    &-delete {
      display: block;
      font: 0.8em var(--font);
      color: #000;
      padding: 0.5em 0 0 0;
      cursor: pointer;
      &:hover {
        color: #dd1919;
      }
    }
  }
}
