@import './../../import.css';

.form-fields .form-field__item,
.content .form-fields .form-field__item {
  &_state_error {
    .form-checkbox {
      &__label {
        &::after {
          border: solid 1px #ff2e2e;
        }
      }
    }
  }
}
