@import './../../import.css';

.form-fields .form-field__item,
.content .form-fields .form-field__item {
  width: 100%;
  font-size: 1em !important;
  position: relative;
  display: table-row;
  @media (--mobile), (--tablet) {
    display: block;
    padding: 0 0 10px 0;
  }
  &::before {
    content: '';
    display: none !important;
  }
  &::after {
    content: '';
    display: none !important;
  }
  &:last-child {
    padding-bottom: 0;
    & .form-field__item-c1 {
      @media (--mobile), (--tablet) {
        display: none;
      }
    }
    & .form-field__item-c2 {
      @media (--mobile), (--tablet) {
        width: 100%;
        text-align: center;
        padding: 15px 0 0 0;
      }
    }
  }
  &-c1 {
    width: 30%;
    display: table-cell;
    vertical-align: top;
    padding: 0.5em 0 0.3em 20px;
    @media (--mobile), (--tablet) {
      width: 100%;
      display: block;
      padding: 0 0 10px 0;
    }
  }
    &-name {
      font: 0.9em var(--font);
      color: #333;
      display: block;
      padding: 0.6em 15px 0 0;
    }
      &-required {
        font: 1em var(--font);
        color: orange;
      }
    &-label {
      width: 100%;
      &-text {
        font: 0.8em var(--font);
        color: #444;
      }
    }
  &-c2 {
    width: 70%;
    display: table-cell;
    vertical-align: top;
    padding: 0.5em 25px 0.3em 0;
    @media (--mobile), (--tablet) {
      width: 100%;
      display: block;
      padding: 0;
    }
    &::after {
      content: '';
      display: block;
      height: 0.5em;
    }
    &-inner {
      position: relative;
    }
  }
  &-error {
    font: 0.8em 'Arial';
    color: red;
    padding: 0.3em 0 0 0;
  }
}
