@import './../../import.css';

.form-radioselect {
  padding: 0.3em 0 0 0;
  &-item {
    padding: 0 0 1em 0;
    cursor: pointer;
    &__label {
      color: #000;
      position: relative;
      display: block;
      padding: 0 0 0 25px;
      cursor: pointer;
      &::after {
        width: 1.1em;
        height: 1.1em;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border: solid 1px #ccc;
        background: #fff;
        border-radius: 50px;
        box-shadow: inset 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        z-index: 1;
      }
    }
      &__radio {
        position: absolute;
        left: 0;
        top: 0;
        & > input {
          position: relative;
          z-index: 2;
          visibility: hidden;
        }
      }
      &__text {
        font: 0.8em var(--font);
        color: #000;
        cursor: pointer;
      }
  }
}
