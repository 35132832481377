@import './../import.css';

/*.form_type_vertical
{
  display: block;
  padding: 0;
  margin: 0 auto;
}
    .form_type_vertical .form-header
    {
      display: none;
    }
    .form_type_vertical .form-fields
    {
      width: 100%;
      display: table;
      table-layout: fixed;
      border-spacing: 0 10px;
      margin-top: -10px;
    }
      .form_type_vertical .form-field__item
      {
        display: table-row;
        padding: 0.5em 0 !important;
      }
        .form_type_vertical .form-field__item-c1
        {
          display: table-cell;
          min-width: 0;
          border-radius: 20px 0 0 20px;
          border: solid 1px #000;
          border-right: 0;
          padding: 0 0 0 15px;
          background: #fff;
        }
          .form_type_vertical .form-field__item-name
          {
            min-width: 0;
            padding: 1.2em 0 0.8em 0;
          }
        .form_type_vertical .form-field__item-c2
        {
          width: 100%;
          display: table-cell;
          border-radius: 0 20px 20px 0;
          border: solid 1px #000;
          border-left: 0;
          margin: 0 0 20px 0;
          background: #fff;
        }
          .form_type_vertical .form-field__item-c2-inner
          {
            padding: 0;
            margin: 0.5em 20px 0 0;
          }
      .form_type_vertical .form-field__item:last-child .form-field__item-c1
      {
        border: none;
        background: none;
      }
      .form_type_vertical .form-field__item:last-child .form-field__item-c2
      {
        border: none;
        background: none;
      }*/
