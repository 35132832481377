@import './../import.css';

.popup {
  position: absolute;
  z-index: 1000000;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  min-width: 985px;
  &::before {
    width: 0;
    height: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
  }
  &-content {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: left;
    margin: 2em 0 2em 0;
    &__link {
      position: absolute;
      z-index: 100003;
      top: 0.5em;
      right: 1.3em;
      text-decoration: none;
      &:hover .popup-content__link-text {
        color: #000;
        border-bottom: dashed 1px #000;
      }
      &-text {
        font: 300 1.1em var(--font);
        cursor: pointer;
        color: #000;
        border-bottom: dashed 1px #000;
      }
    }
    &__inner {
      width: 100%;
      height: 300px;
      display: table;
      transition: height 0.5s ease-out;
    }
  }
}
